export const cardsRegiones = [
  {
    headerImage: {
      image: require("../../assets/images/regionGLP_2022.png"),
      alt: "Anillo del Gran La Plata",
    },
    title: "Anillo del Gran La Plata",
    subtitle:
      "<b>Investigación: Casa Río Lab</b> " +
      "<br/><br/>" +
      "El corredor biocultural del Gran La Plata es un anillo productivo en términos alimentarios,  " +
      "pero también ecológicos y culturales que se enfrenta a la invasión desde fuera y al  " +
      "desarrollo descoordinado desde dentro, amenazando su propia existencia. El objetivo de " +
      "másesta iniciativa es definir los roles de las comunidades urbanas, ecológicas y agrícolas " +
      "estableciendo las condiciones para preservar las identidades y la biodiversidad.",
    button: {
      label: "ver cartografía",
      onClick: () =>
        window.open(
          "https://map.casariolab.art/#/valle/biocultural?center=-34.955,-57.946&zoom=9.478"
        ),
    },
  },
  {
    headerImage: {
      image: require("../../assets/images/regionMR_2022.png"),
      alt: "Cuenca Matanza-Riachuelo",
    },
    title: "Cuenca Matanza-Riachuelo",
    subtitle:
      "<b>Investigación: FARN</b> " +
      "<br/><br/>" +
      "Esta cuenca es conocida popularmente por el gran nivel de contaminación debido a la" +
      "densidad poblacional que la rodea y a las plantas industriales que vierten desechos sin el " +
      "adecuado tratamiento a sus aguas. Sin embargo, en la parte alta de la cuenca, pueden " +
      "encontrarse ecosistemas donde la vida no humana encuentra hábitat propicios para su sano " +
      "desarrollo. Asimismo es una zona con gran riqueza cultural que integra tanto la vida rural " +
      "como la urbana.",
    button: {
      label: "ver cartografía",
      onClick: () =>
        window.open(
          "https://map.casariolab.art/#/valle/biocultural?center=-34.868,-58.708&zoom=8.943"
        ),
    },
  },
  {
    headerImage: {
      image: require("../../assets/images/regionAL_2022.png"),
      alt: "Cuenca Ludueña",
    },
    title: "Cuenca Ludueña",
    subtitle:
      "<b>Investigación: Taller Ecologista</b>" +
      "<br/><br/>" +
      "El Arroyo Ludueña nace en cercanías de la localidad de Zavalla y es tributario del río " +
      "Paraná. Los terrenos linderos al arroyo, constituyen su valle de inundación, sus suelos son " +
      "bajos y presentan características de humedal, con bañados y cañadas. La organización " +
      "rosarina Taller Ecologista, aliada en el programa Humedales Sin Fronteras, considera " +
      "importante la visibilización y conservación de toda esta zona de humedales pampeanos en " +
      "Rosario y el área metropolitana, frente al creciente proceso de urbanización e instalación de " +
      "proyectos industriales.",
    button: {
      label: "ver cartografía",
      onClick: () =>
        window.open(
          "https://map.casariolab.art/#/valle/biocultural?center=-32.933,-60.884&zoom=9.651"
        ),
    },
  },
  {
    headerImage: {
      image: require("../../assets/images/regionFCS_2022.png"),
      alt: "Franja Costera Sur",
    },
    title: "Franja Costera Sur",
    subtitle:
      "<b>Investigación: Casa Río Lab</b>" +
      "<br/><br/>" +
      "Este corredor se ubica en la Franja Costera Sur del estuario del Río de La Plata y" +
      "comprende una gran extensión de albardones costeros. En toda sus superficie conviven" +
      "grandes zonas de bosque nativo con emprendimientos inmobiliarios privados que" +
      "amenazan su biodinámica. La Franja Costera Sur funciona como puente enlazando el anillo" +
      "del Gran La Plata y desembocadura de la Cuenca Matanza-Riachuelo",
    button: {
      label: "ver cartografía",
      onClick: () =>
        window.open(
          "https://map.casariolab.art/#/valle/biocultural?center=-34.739,-58.175&zoom=9.964"
        ),
    },
  },
];

export const corredorSlide = {
  imageOne: {
    src: require("../../assets/images/extractivista.png"),
    alt: "Extractivista",
  },
  imageTwo: {
    src: require("../../assets/images/biocultural.png"),
    alt: "Biocultural",
  },
};
