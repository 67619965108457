import React, { useState } from "react";

import "./styles.scss";
import clsx from "clsx";

const CorredorSlide = ({ imageOne, imageTwo }) => {
  const [rightActive, setRightActive] = useState(false);
  const [leftActive, setLeftActive] = useState(false);

  return (
    <div className={"corredor-slide-main"}>
      <div className={"corredor-slide-container"}>
        <header
          className={clsx(["side"], {
            "left-side": true,
            "side-inactive": rightActive,
          })}
        >
          <img
            src={imageOne.src}
            alt={imageOne.href}
            className={"left-side-logo"}
            onClick={() => {
              setRightActive(false);
              setLeftActive(!leftActive);
            }}
          />
        </header>
        <header
          className={clsx("side", {
            "right-side": true,
            "side-inactive": leftActive,
          })}
        >
          <img
            src={imageTwo.src}
            alt={imageTwo.alt}
            className={clsx({
              "right-side-logo": true,
              "right-side-logo-active": rightActive,
            })}
            onClick={() => {
              setLeftActive(false);
              setRightActive(!rightActive);
            }}
          />
        </header>
      </div>
    </div>
  );
};

export default CorredorSlide;
