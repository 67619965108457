export const navbarLinks = [
  {
    label: "regiones bioculturales",
    href: "#regiones-bioculturales",
    name: "regiones-bioculturales",
  },
  {
    label: "corredor extractivista",
    href: "#corredor-extractivista",
    name: "corredor-extractivista",
  },
  {
    label: "mapa colaborativo",
    href: "#mapa-colaborativo",
    name: "mapa-colaborativo",
  },
];
