import React from "react";
import { withTranslation } from "react-i18next";

import Home from "components/templates/Home";
import { cardsRegiones, corredorSlide } from "./constants";

const HomeView = (props) => {
  const portadaHome = {
    title:
      "Cartografía del corredor biocultural del Valle Central de la Cuenca del Plata",
    subtitle:
      "Una investigación sobre la historia cultural, biológica y extractiva del sistema de humedales del valle central de la Cuenca del Plata, uno de los corredores de vida más importantes del planeta.",
    buttons: [
      {
        label: "¿cómo funciona?",
        className: "inverted",
        onClick: () => alert("click como funciona"),
      },
      {
        label: "ver cartografía",
        onClick: () => window.open("https://map.casariolab.art/"),
      },
    ],
    image: {
      src: require("../../assets/images/mapaPortada.png"),
      alt: "Mapa Portada",
    },
  };

  const regionesBioculturales = {
    title: "Regiones Bioculturales",
    subtitle:
      "Esta cartografía se enfoca en detectar e inspirar a identificar, regiones que " +
      "salvaguardan su patrimonio natural y cultural material e inmaterial. La intención es " +
      "mantener ecosistemas sanos interconectados, favoreciendo la conexión de áreas " +
      "protegidas existentes y futuras, impulsando procesos de restauración, y  " +
      "promoviendo usos social y ecológicamente sostenibles.",
    cards: cardsRegiones,
  };

  const corredorExtractivista = {
    title: "Corredor Extractivista",
    subtitle:
      "La cartografía cuenta a la vez con una vista en la podemos observar cómo las redes " +
      "extractivas y su logística distribuyen la ecología de la región con otras distantes. Los " +
      "estándares de explotación arrasan con formas de vida despedazando áreas naturales " +
      "en beneficio de una estructura económica que apunta a profundizar las heridas del " +
      "ADN extractivista de Latinoamérica.",
    slide: corredorSlide,
  };

  const mapaColaborativo = {
    title: "Mapa Colaborativo",
    subtitle:
      "Herramienta de mapeo colaborativo elaborado por las personas que viven, sienten y " +
      "conocen su lugar a lo largo de este extenso corredor de humedales del valle central " +
      "de la Cuenca del Plata. Este es un ejercicio de visualización que pretende crear " +
      "conocimiento y potenciar los procesos colectivos de integración territorial, dando " +
      "ventaja a la emergencia de nuevas visiones desde los pueblos y de las actividades " +
      "que sostienen la red de la vida. ",
    button: {
      label: "ver mapa",
      onClick: () => window.open("https://mapa.casarioarteyambiente.org/"),
    },
    image: {
      src: require("../../assets/images/colaborativo.png"),
      alt: "Extractivista",
    },
  };

  return (
    <Home
      portadaHome={portadaHome}
      regionesBioculturales={regionesBioculturales}
      corredorExtractivista={corredorExtractivista}
      mapaColaborativo={mapaColaborativo}
    />
  );
};

export default withTranslation()(HomeView);
