import React from "react";
import "./styles.scss";

const ActionButton = ({ label, onClick, className, type = "button" }) => {
  return (
    <input
      type={type}
      className={"action-button " + className}
      value={label}
      onClick={onClick}
    />
  );
};

export default ActionButton;
