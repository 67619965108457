import React from "react";

import "./styles.scss";
import CorredorSlide from "../CorredorSlide";

const CorredorExtractivista = ({ title, subtitle, slide }) => {
  return (
    <div className={"regiones-container"}>
      <div className={"regiones-content corredor-content"}>
        <h2>{title}</h2>
        <h4>{subtitle}</h4>
        <CorredorSlide {...slide} />
      </div>
    </div>
  );
};

export default CorredorExtractivista;
