import React from "react";

import "./styles.scss";

const Footer = () => {
  return (
    <div className={"footer-portada"}>
      <img
        src={require("../../../assets/images/HSF.png")}
        alt={"Logo Humedales Sin Fronteras"}
      />
      <img
        src={require("../../../assets/images/casario.png")}
        alt={"Logo Casa Río"}
      />
    </div>
  );
};

export default Footer;
