import React from "react";
import { withTranslation } from "react-i18next";
// import { useHistory } from "react-router-dom";
//
// import { isMobile } from "helpers/Mobile";

import "./styles.scss";
import Navbar from "components/molecules/Navbar";
import Footer from "components/molecules/Footer";

const Layout = (props) => {
  return (
    <div className={"layout-portada-container"}>
      <div className={"layout-navbar-container"}>
        <Navbar />
      </div>
      <div className={"layout-content-container"}>
        <div className={"layout-content"}>{props.children}</div>
      </div>
      <div className={"layout-footer-container"}>
        <Footer />
      </div>
    </div>
  );
};

export default withTranslation()(Layout);
