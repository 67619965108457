import React from "react";
import { withTranslation } from "react-i18next";

// import { isMobile } from "helpers/Mobile";

import "./styles.scss";
import Layout from "../Layout";
import PortadaHome from "components/molecules/PortadaHome";
import RegionesBioculturales from "components/molecules/RegionesBioculturales";
import CorredorExtractivista from "components/molecules/CorredorExtractivista";
import MapaColaborativo from "components/molecules/MapaColaborativo";

const Home = ({
  portadaHome,
  regionesBioculturales,
  corredorExtractivista,
  mapaColaborativo,
}) => {
  return (
    <Layout>
      <PortadaHome {...portadaHome} />
      <div className={"hl"} />
      <div id={"regiones-bioculturales"}>
        <RegionesBioculturales {...regionesBioculturales} />
      </div>
      <div className={"hl"} />
      <div id={"corredor-extractivista"}>
        <CorredorExtractivista {...corredorExtractivista} />
      </div>
      <div id={"mapa-colaborativo"}>
        <MapaColaborativo {...mapaColaborativo} />
      </div>
    </Layout>
  );
};

export default withTranslation()(Home);
