import React from "react";

import "./styles.scss";
import { Card } from "react-bootstrap";
import ActionButton from "../../atoms/ActionButton";

const CardRegiones = ({
  headerImage = { href: "", alt: "" },
  title = "",
  subtitle = "",
  button = { label: "", onClick: "" },
}) => {
  return (
    <Card className={"card-regiones-container"}>
      <Card.Img variant="top" src={headerImage.image} />
      <Card.Body>
        <div className={"card-regiones-body"}>
          <Card.Title>{title}</Card.Title>
          <Card.Text
            className={"card-regiones-body-text scrollbar scrollbar-style"}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: subtitle,
              }}
            />
          </Card.Text>
          <div>
            <ActionButton
              label={button.label}
              className={"card-button"}
              onClick={button.onClick}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CardRegiones;
