import React from "react";

import "./styles.scss";
import CardRegionesSlide from "../CardRegionesSlide";

const RegionesBioculturales = ({ title, subtitle, cards }) => {
  return (
    <div className={"regiones-container"}>
      <div className={"regiones-content"}>
        <h2>{title}</h2>
        <h4>{subtitle}</h4>
      </div>
      <div className={"regiones-content-cards"}>
        <CardRegionesSlide cards={cards} />
      </div>
    </div>
  );
};

export default RegionesBioculturales;
