import React from "react";
//
// import LoginHeader from "components/atoms/LoginHeader";
// import { isMobile } from "helpers/Mobile";

import "./styles.scss";
import { navbarLinks } from "components/templates/Layout/constants";

const Navbar = () => {
  return (
    <>
      <div className={"navbar-portada"}>
        <div>
          <a href={"/"}>
            <img
              src={require("../../../assets/images/logo.png")}
              alt={"Logo Cuenca Biocultural"}
            />
          </a>
        </div>
        <div className={"navbar-links"}>
          {navbarLinks.map((link) => (
            <React.Fragment key={link.name}>
              <a href={link.href} key={link.name}>
                {link.label}
              </a>
              <div className="vl" />
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className={"hl-nav"} />
    </>
  );
};

export default Navbar;
