import React from "react";
import { Switch, Route } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./App.css";

import { Context, ContextPersist } from "./store/context";

import HomeView from "./views/Home/HomeView";

function App() {
  return (
    <ContextPersist>
      <Context>
        <Switch>
          <Route exact path="/" component={HomeView} />
        </Switch>
      </Context>
    </ContextPersist>
  );
}

export default App;
