import React from "react";
import Slider from "react-slick";

import "./styles.scss";
import CardRegiones from "../CardRegiones";

import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mediumViewport } from "../../../helpers/Viewports";

const CardRegionesSlide = ({ cards }) => {
  const leftArrow = () => {
    return (
      <div className="left-arrow">
        <FontAwesomeIcon
          icon={faChevronCircleLeft}
          onClick={() => slider?.current?.slickPrev()}
          className={"regiones-slider-arrow"}
        />
      </div>
    );
  };

  const rightArrow = () => {
    return (
      <div className="right-arrow">
        <FontAwesomeIcon
          icon={faChevronCircleRight}
          onClick={() => slider?.current?.slickNext()}
          className={"regiones-slider-arrow"}
        />
      </div>
    );
  };

  const sliderSettings = {
    dots: false,
    slidesToShow: mediumViewport ? 3 : 4,
    infiniteScroll: true,
    arrows: false,
  };
  const cardss = [...cards, ...cards];

  const slider = React.useRef(null);

  return (
    <div className={"regiones-slider-container"}>
      {leftArrow()}
      {rightArrow()}
      <Slider ref={slider} {...sliderSettings}>
        {/*<div className={"constants-container"}>*/}
        {cardss.map((card, index) => (
          <div align={"center"} key={index}>
            <CardRegiones {...card} />
          </div>
        ))}
        {/*</div>*/}
      </Slider>
    </div>
  );
};

export default CardRegionesSlide;
