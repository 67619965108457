import React from "react";

import { mediumViewport } from "helpers/Viewports";

import "./styles.scss";
import ActionButton from "../../atoms/ActionButton";

const PortadaHome = ({ title, subtitle, buttons, image }) => {
  return (
    <div className={"portada-home"}>
      <div className={"portada-content"}>
        <h1>{title}</h1>
        <h4>{subtitle}</h4>
        <div className={"portada-home-botones"}>
          {buttons.map((button, inx) => (
            <ActionButton {...button} key={inx} />
          ))}
        </div>
      </div>
      <div>
        <img src={image.src} alt={image.alt} />
      </div>
    </div>
  );
};

export default PortadaHome;
