import React from "react";

import "./styles.scss";
import ActionButton from "components/atoms/ActionButton";

const MapaColaborativo = ({ title, subtitle, button, image }) => {
  return (
    <div className={"regiones-container mapa-container"}>
      <div className={"regiones-content mapa-content"}>
        <h2>{title}</h2>
        <img src={image.src} alt={image.alt} />
        <h4 dangerouslySetInnerHTML={{ __html: subtitle }} />
        <ActionButton
          className={"inverted boton-ver-mapa"}
          label={button.label}
          onClick={button.onClick}
        />
      </div>
    </div>
  );
};

export default MapaColaborativo;
